<template>
	<div id="serviceList" class="el-content">
		<a-space class="mb12">
			<a-button type="primary" v-has="{action:'wxapp_goods_server_add',plat:isShop}" @click="editService(0)">
				<i class="ri-add-line"></i>新增服务
			</a-button>
			<a-button type="danger" v-has="{action:'wxapp_goods_server_del',plat:isShop}" @click="deleteService(pks)">
				<i class="ri-delete-bin-line"></i>批量删除
			</a-button>
		</a-space>
		
		<a-table row-key="id" size="small" :pagination="false" :data-source="info.list" :defaultExpandAllRows="true" :columns='[
			{dataIndex:"id",title:"ID"},
			{dataIndex:"name",title:"服务名称"},
			{dataIndex:"rank",title:"排序"},
			{dataIndex:"update_time",title:"最后更新时间"},
			{dataIndex:"action",title:"操作",slots:{customRender:"action"}},
		 ]' :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
			<template #action="{record}">
				<a-space>
					<kd-button 
						type="primary" 
						icon="ri-edit-2-line" 
						title="编辑" 
						@click="editService(record)" 
						v-has="{action:'wxapp_goods_server_add',plat:isShop}">
					</kd-button>
					<kd-button 
						type="danger" 
						icon="ri-delete-bin-5-line" 
						title="删除" 
						@click="deleteService([record.id])" 
						v-has="{action:'wxapp_goods_server_del',plat:isShop}">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getServiceListgetServiceList(info.page,e)}"
				@change="(e)=>{getServiceList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.edit" title="添加/编辑服务信息" @ok="saveGoodsService" width="600px" @cancel="show.edit = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="服务名称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="服务内容">
					<a-textarea v-model:value="form.content" placeholder="服务内容" :rows="4" />
				</a-form-item>
				<a-form-item label="服务排序" >
					<a-input v-model:value="form.rank" type="number"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import goodsModel from '@/api/goods.js'
import common from '@/api/common.js'
export default{
	name:'page-server-list',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
		const _d = reactive({
			pks:[],
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			show:{
				edit:false
			},
			form:null
		})
		getServiceList(1,_d.info.limit)
		function getServiceList(page,limit){
			goodsModel.getGoodsServer(page,limit,{},res=>_d.info = {limit,...res})
		}
		function editService(row){
			_d.form = {
				id:row.id ? row.id :0,
				name:row ? row.name : '',
				rank:row ? row.rank : 99,
				content:row ? row.content:''
			}
			_d.show.edit = true
		}
		const deleteService = (id)=>common.deleteDataList(id,9,"确认删除该商品服务吗?").then(()=>{
			getServiceList(_d.info.page,_d.info.limit)
		})
		const saveGoodsService = ()=>goodsModel.createOrEditGoodsServer(_d.form,()=>{
			getServiceList(_d.info.page,_d.info.limit)
			_d.show.edit = false
		})
		return{
			...toRefs(_d),
			getServiceList,
			deleteService,
			editService,
			saveGoodsService
		}
	},
}
</script>

<style>

</style>
